import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import renderHTML from "react-render-html";
// @material-ui/icons
import { NoSsr } from "@material-ui/core";
import Card from "components/atoms/ItemCard";
import ProductSection from "./Sections/HtmlSection.js";
// core components
import GridContainer from "components/uikit/Grid/GridContainer.js";
import GridItem from "components/uikit/Grid/GridItem.js";
// import Button from "components/uikit/CustomButtons/Button.js";
import Parallax from "components/uikit/Parallax/Parallax.js";
import styles from "assets/jss/material-kit-react/views/mainPage.js";
// Sections for this page
import styled from "styled-components";

import SectionTitle from "components/atoms/SectionTitle";
import { API, graphqlOperation } from "aws-amplify";
import { getShowBooth, listShowBoothItems } from "graphql/queries";
import { navigate } from "gatsby";
import BoothUserInfo from "components/molecures/BoothUserInfo";
import Breadcrumbs from "components/molecures/Breadcrumbs";

const useStyles = makeStyles(styles);

export const BoothItems = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: end;
`;

export const BoothItem = styled.div`
  width: 100%;
  max-width: 320px;
  padding-right: 4px;
  padding-bottom: 8px;
  flex: auto;
`;

const LogoImg = styled.img`
  width: 100%;
`;

const Logos = styled.div`
  width: 100%;
  padding: 2rem;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoItem = styled.div`
  width: 100%;
  padding: 10px;
  max-width: 120px;
  flex: auto;
`;

export default function Main(props) {
  const classes = useStyles();
  const { show, booth, info } = props;
  const [data, setData] = React.useState();
  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    async function f() {
      const res = await API.graphql(
        // @ts-ignore
        graphqlOperation(getShowBooth, {
          id: booth.id,
        })
      );

      const variables = {
        filter: {
          boothID: { eq: booth.id },
        },
      };
      const boothItems = await API.graphql(
        // @ts-ignore
        graphqlOperation(listShowBoothItems, variables)
      );
      const sortedItems = boothItems.data.listShowBoothItems.items.sort(
        (a, b) => {
          if (a.priority && b.priority) {
            return b.priority - a.priority;
          } else if (a.priority && !b.priority) {
            return -1;
          } else if (!a.priority && b.priority) {
            return 1;
          } else {
            return 0;
          }
        }
      );
      // res.data.getShowBooth.brandLogos = res.data.getShowBooth.brandLogos.sort(
      //   (a, b) => {
      //     if (a.priority && b.priority) {
      //       return b.priority - a.priority;
      //     } else if (a.priority && !b.priority) {
      //       return -1;
      //     } else if (!a.priority && b.priority) {
      //       return 1;
      //     } else {
      //       return 0;
      //     }
      //   }
      // );

      setItems(sortedItems);
      // @ts-ignore
      setData(res.data.getShowBooth);
    }
    f();
  }, [booth.id]);

  return (
    <div>
      {show && data && (
        <>
          <Parallax
            filter
            style={{ height: "40vh" }}
            image={data.backgroundImage ? data.backgroundImage.url : null}
          >
            <div className={classes.container}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                  <h2 className={classes.title}>{renderHTML(data.title)}</h2>
                  {/* {data.discription && (
                    <div className={classes.discription}>
                      {renderHTML(data.discription)}
                    </div>
                  )} */}
                </GridItem>
              </GridContainer>
            </div>
          </Parallax>
          <div className={classNames(classes.main, classes.mainRaised)}>
            <div className={classes.container}>
              <Breadcrumbs show={show} booth={booth} />
              {data && data.section && <ProductSection data={data.section} />}

              <SectionTitle>取り扱いブランド</SectionTitle>

              {data && (
                <Logos>
                  {data.brandLogos.phots.items
                    .sort((a, b) => a.albumOrder - b.albumOrder)
                    .map((logo: any) => (
                      <LogoItem>
                        <LogoImg
                          src={logo.phot.url}
                          alt={logo.phot.alternate}
                        />
                      </LogoItem>
                    ))}
                </Logos>
              )}
              <SectionTitle>出展リスト</SectionTitle>
              <BoothItems>
                {items.map((item) => (
                  <BoothItem
                    onClick={() =>
                      navigate(`/${show.slug}/boothitem/${item.id}`)
                    }
                  >
                    <Card data={item} />
                  </BoothItem>
                ))}
              </BoothItems>
              {info && (
                <>
                  <SectionTitle>お問い合わせ</SectionTitle>
                  <BoothUserInfo data={info} />
                </>
              )}

              {/* <TeamSection />
          <WorkSection /> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
