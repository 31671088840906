import * as React from "react";
import "@aws-amplify/ui/dist/style.css";
import Booth from "components/pages/front/Booth";
import Layout from "components/layouts/Common";
import { Helmet } from "react-helmet";
import { GatsbySeo } from "gatsby-plugin-next-seo";
type Props = {
  pageContext: any;
};

export default function App(props: Props) {
  const { pageContext } = props;
  const { show, booth, info } = pageContext;

  return (
    <div className="App">
      {show && booth && (
        <GatsbySeo
          title={`${show.title} ${booth.name}`}
          description={show.description}
          openGraph={{
            url: `https://www.onlineboatshow.jp/${show.slug}/booth/${booth.id}`,
            title: `${show.title} ${booth.name}`,
            description: `${show.title} ${booth.name}`,
            site_name: "ONLINE BOATSHOW オンラインボートショー",
            images: [
              {
                url:
                  booth.backgroundImage && booth.backgroundImage?.url
                    ? booth.backgroundImage.url
                    : show.ogpImage.url,
                alt: `${show.title} ${booth.name}`,
              },
            ],
          }}
        />
      )}
      <Helmet>
        <title>{`${show.title} ${booth.name}`}</title>
      </Helmet>

      <Layout show={show}>
        <Booth show={pageContext.show} booth={booth} info={info} />
      </Layout>
    </div>
  );
}
