import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  badge: {
    height: 24,
  },
  media: {
    height: 140,
  },
});

type Props = {
  data: any;
};

export default function ItemCard(props: Props) {
  const { data } = props;
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        {data.mainImage && (
          <CardMedia
            className={classes.media}
            image={data.mainImage.url}
            title={data.name}
          />
        )}
        <CardContent>
          <Typography gutterBottom variant="body1">
            {data.name}
          </Typography>
          <div className={classes.badge}>
            {data.badge && (
              <Chip
                label={data.badge}
                variant="outlined"
                size="small"
                color="primary"
              />
            )}
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
